import React from "react";
import { Button, VStack, Text } from "@chakra-ui/react";
import { Place } from "../utils";
import { useNavigation } from "../hooks/useNavigation";

type Props = {
  currentNavigationTarget: Place;
  onStopNavigation: () => void;
};

export const NavigationInfo: React.FC<Props> = ({
  currentNavigationTarget,
  onStopNavigation,
}) => {
  const { distance, bearing } = useNavigation(
    currentNavigationTarget.coordinates,
  );
  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="xl">Vzdialenosť: {distance?.toFixed(0)} m</Text>
      <Text fontSize="xl">Azimut: {bearing?.toFixed(0)}°</Text>
      <Button onClick={onStopNavigation} colorScheme="primary">
        Prestaň navigovať
      </Button>
    </VStack>
  );
};
