import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Place } from "./utils";

export type Submit = {
  code: string;
  teamCode: string;
  timestamp: number;
};

type StoreState = {
  teamCode: string | null;
  setTeamCode: (code: string | null) => void;
  checkpointSubmits: Submit[];
  skipSubmits: Submit[];
  addCheckpointSubmit: (submit: Omit<Submit, 'teamCode'>) => void;
  addSkipSubmit: (submit: Omit<Submit, 'teamCode'>) => void;
  setCheckpointSubmits: (submits: Submit[]) => void;
  setSkipSubmits: (submits: Submit[]) => void;
  currentNavigationTarget: Place | null;
  setCurrentNavigationTarget: (target: Place | null) => void;
  logout: () => void;
};

export const useStore = create<StoreState>()(
  persist(
    (set) => ({
      teamCode: null,
      setTeamCode: (code) => set({ teamCode: code }),
      checkpointSubmits: [],
      skipSubmits: [],
      setCheckpointSubmits: (submits: Submit[]) => set({ checkpointSubmits: submits }),
      addCheckpointSubmit: (submit: Omit<Submit, 'teamCode'>) =>
        set((state) => ({
          checkpointSubmits: state.teamCode ? [...state.checkpointSubmits, {...submit, teamCode: state.teamCode.toLowerCase().trim()}] : state.checkpointSubmits,
        })),
      setSkipSubmits: (submits: Submit[]) => set({ skipSubmits: submits }),
      addSkipSubmit: (submit: Omit<Submit, 'teamCode'>) =>
        set((state) => ({
          skipSubmits: state.teamCode ? [...state.skipSubmits, {...submit, teamCode: state.teamCode.toLowerCase().trim()}] : state.skipSubmits,
        })),
      currentNavigationTarget: null,
      setCurrentNavigationTarget: (target: Place | null) =>
        set(() => ({
          currentNavigationTarget: target,
        })),
      logout: () => set({ teamCode: null, currentNavigationTarget: null }),
    }),
    {
      name: "sregom-submits", // localStorage key
    },
  ),
);
