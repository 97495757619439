import {Submit} from "./store";
import { useStore } from "./store";

const GOOGLE_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbxvjff35NAPBAmOeQxY0kq3ogl_vAqvC212v3xlxYLG8qsIv9-3G8Ndhe9Rc01c3UFx6w/exec';

export const sendSubmitsToGoogleSheet = async (submits: Submit[]) => {
  try {
    await fetch(GOOGLE_SCRIPT_URL, {
      method: 'POST',
      mode: 'no-cors', // Important for CORS issues with Google Apps Script
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submits),
    })
  } catch (error) {
    console.error('Error:', error);
  }
}

export const useFetchTeamSubmits = (teamCode: string | null) => {
  const checkpointSubmits = useStore(state => state.checkpointSubmits);
  const skipSubmits = useStore(state => state.skipSubmits);
  const setCheckpointSubmits = useStore(state => state.setCheckpointSubmits);
  const setSkipSubmits = useStore(state => state.setSkipSubmits);

  const fetchTeamSubmits = async () => {
    if (!teamCode) return
    const response = await fetch(`${GOOGLE_SCRIPT_URL}?action=fetchTeamSubmits&teamCode=${teamCode}`)
    const data = await response.json() as {submits: Submit[], skips: Submit[]};
    data.submits.forEach(submit => {
      const existingSubmit = checkpointSubmits.find(s => s.code === submit.code);
      if (existingSubmit) {
        if (new Date(existingSubmit.timestamp).getTime() > new Date(submit.timestamp).getTime()) {
          existingSubmit.timestamp = submit.timestamp;
        }
      } else {
        checkpointSubmits.push(submit);
      }
    });

    setCheckpointSubmits([...checkpointSubmits]);

    data.skips.forEach(submit => {
      const existingSubmit = skipSubmits.find(s => s.code === submit.code);
      if (existingSubmit) {
        if (new Date(existingSubmit.timestamp).getTime() > new Date(submit.timestamp).getTime()) {
          existingSubmit.timestamp = submit.timestamp;
        }
      } else {
        skipSubmits.push(submit);
      }
    });

    setSkipSubmits([...skipSubmits]);

  }

  return {fetchAndUpdateTeamSubmits: fetchTeamSubmits};
}

type TeamResult = {
  team: string;
  lastCheckpoint: number;
  totalTime: number;
  penalties: number;
  finalTime: number;
};

export type ResultsData = TeamResult[];

export const fetchResults = async () => {
  try {
    const response = await fetch(GOOGLE_SCRIPT_URL + '?action=calculateResults');
    const data = await response.json() as ResultsData;
    return data;
  } catch (error) {
    console.error('Error fetching results:', error);
  }
}

export type CheckpointData = {
  [team: string]: {
    [key: string]: number; // The key can be "checkpoint1", "checkpoint2", "skip1", "skip2", etc., and the value is the timestamp.
  };
};

export const fetchCheckpoints = async () => {
  try {
    const response = await fetch(GOOGLE_SCRIPT_URL + '?action=fetchCheckpoints' );
    console.log(response);
    const data = await response.json() as CheckpointData;
    return data;
  } catch (error) {
    console.error('Error fetching results:', error);
  }
};
