import React from "react";
import { useStore } from "../store";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const teamCode = useStore((state) => state.teamCode);

  if (!teamCode) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};
