import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Heading,
} from "@chakra-ui/react";
import { format } from "date-fns";
import CHECKPOINTS from "../data/checkpoints.json";

type Props = {
  submits: { code: string; timestamp: number }[];
};

export const SubmitsTable: React.FC<Props> = ({ submits }) => {
  return (
    <Box>
      <Heading marginBottom={4}>Tabuľka submitov</Heading>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Kód</Th>
            <Th>Čas</Th>
            <Th>Typ</Th>
          </Tr>
        </Thead>
        <Tbody>
          {submits.map((submit, index) => (
            <Tr key={index}>
              <Td>{submit.code}</Td>
              <Td>{format(submit.timestamp, "kk:mm:ss")}</Td>
              <Td>
                {CHECKPOINTS.includes(submit.code) ? "Checkpoint" : "Skip"}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
