import React from "react";
import { VStack, Text, Heading } from "@chakra-ui/react";
import { format, differenceInMinutes } from "date-fns";
import { getSkipInfo } from "../utils";

type Props = {
  lastCheckpointSubmit: { code: string; timestamp: number } | null;
  currentTime: number;
};

export const CheckpointInfo: React.FC<Props> = ({
  lastCheckpointSubmit,
  currentTime,
}) => {
  const elapsedTime = lastCheckpointSubmit
    ? differenceInMinutes(currentTime, lastCheckpointSubmit.timestamp)
    : null;

  if (!lastCheckpointSubmit) return null;

  return (
    <VStack spacing={4} align="stretch">
      {lastCheckpointSubmit && (
        <>
          <Heading>Aktuálne stanovište</Heading>
          <Text>Kód: {lastCheckpointSubmit.code}</Text>
          <Text>
            Príchod: {format(lastCheckpointSubmit.timestamp, "kk:mm:ss")}
          </Text>
          <Text>Čas na stanovišti: {elapsedTime} min</Text>
          {elapsedTime !== null && (
            <Text>Skip: {getSkipInfo(elapsedTime)}</Text>
          )}
        </>
      )}
    </VStack>
  );
};
