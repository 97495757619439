import React, {useEffect, useState} from 'react'
import {CheckpointData, fetchCheckpoints} from "../api";
import {Box, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {format} from "date-fns";

const MAX_CHECKPOINTS = 14;

export const TeamProgress = () => {

  const [checkpointData, setCheckpointData] = useState<CheckpointData>({});

  useEffect(() => {
    const getResults = async () => {
      const data = await fetchCheckpoints();
      if (!data) return;

      setCheckpointData(data);
    };

    getResults();
  }, []);

  const teams = Object.keys(checkpointData);

  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Team</Th>
          {Array.from({ length: 12 }).map((_, idx) => (
            <Th key={idx}>STN{idx + 1}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {teams.map((team) => (
          <Tr key={team}>
            <Td>{team}</Td>
            {Array.from({ length: MAX_CHECKPOINTS }).map((_, idx) => {
              const checkpointKey = `checkpoint${idx + 1}`;
              const skipKey = `skip${idx + 1}`;
              const isLastVisited = !checkpointData[team][`checkpoint${idx + 2}`] && checkpointData[team][checkpointKey];
              const bgColor = checkpointData[team][skipKey]
                ? "orange"
                : checkpointData[team][checkpointKey]
                  ? isLastVisited
                    ? "default"
                    : "green"
                  : "default";


              return (
                <Td key={idx} bgColor={bgColor}>
                  <Box>{checkpointData[team][checkpointKey] && format(checkpointData[team][checkpointKey], "HH:mm:ss")}</Box>
                  <Box>{checkpointData[team][skipKey] && format(checkpointData[team][skipKey], "HH:mm:ss")}</Box>
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}