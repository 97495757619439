import React, { useState } from "react";
import { useStore } from "../store";
import CHECKPOINTS from '../data/checkpoints.json'
import PLACES from '../data/skips.json'
import { InputCode } from "./InputCode";
import { NavigationInfo } from "./NavigationInfo";
import { CheckpointInfo } from "./CheckpointInfo";
import { SubmitsTable } from "./SubmitsTable";
import { useCurrentTime } from "../hooks/useCurrentTime";
import {Divider, HStack, VStack, Text, Button, useToast} from "@chakra-ui/react";
import logo from "../logo-title.png";
import {sendSubmitsToGoogleSheet, useFetchTeamSubmits} from "../api";

const TEST_PLACE = {
  code: 'test',
  "coordinates": {
    "lat": 48.20906580078916,
    "lng": 17.16049182501267
  }
}

export const MainApp = () => {
  const [code, setCode] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);
  const toast = useToast()

  const teamCode = useStore((state) => state.teamCode);
  const logout = useStore((state) => state.logout);
  const checkpointSubmits = useStore((state) => state.checkpointSubmits.filter(s => s.teamCode === teamCode?.toLowerCase().trim()));
  const addCheckpointSubmit = useStore((state) => state.addCheckpointSubmit);
  const skipSubmits = useStore((state) => state.skipSubmits.filter(s => s.teamCode === teamCode?.toLowerCase().trim()));
  const addSkipSubmit = useStore((state) => state.addSkipSubmit);
  const currentNavigationTarget = useStore(
    (state) => state.currentNavigationTarget,
  );
  const setCurrentNavigationTarget = useStore(
    (state) => state.setCurrentNavigationTarget,
  );

  const {fetchAndUpdateTeamSubmits} = useFetchTeamSubmits(teamCode);

  const allSubmits = [...checkpointSubmits, ...skipSubmits].sort(
    (a, b) => b.timestamp - a.timestamp,
  );

  const sortedCheckpointSubmits = checkpointSubmits.sort(
    (a, b) => b.timestamp - a.timestamp,
  );

  const lastCheckpointSubmit = sortedCheckpointSubmits[0];
  const currentTime = useCurrentTime();
  const handleCodeChange = (code: string) => {
    setCode(code
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
    );
  };


  const onSubmit = () => {
    if (CHECKPOINTS.includes(code)) {
      const checkpointExists = checkpointSubmits.some((cp) => cp.code === code);
      if (!checkpointExists) {
        addCheckpointSubmit({
          code,
          timestamp: Date.now(),
        });
        toast({
          title: `Stanovište ${code.toUpperCase()}`,
          description: `Úspešne ste dosiahli stanovište ${code.toUpperCase()}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast({
          title: `Stanovište ${code.toUpperCase()}`,
          description: "Toto stanovište ste už navštívili",
          status: "warning",
          duration: 5000,
          isClosable: true,
        })
      }
      setCode("");
      return;
    }

    if (code === TEST_PLACE.code) {
      setCurrentNavigationTarget(TEST_PLACE);
      return;
    }

    const place = PLACES.find((p) => p.code === code);
    if (!place) {
      toast({
        title: "Neplatný kód",
        description: `Kód ${code} neexistuje`,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      setCode("");
      return;
    }

    const skipExists = skipSubmits.some((cp) => cp.code === code);
    if (!skipExists) {
      addSkipSubmit({
        code,
        timestamp: Date.now(),
      });
    }

    setCurrentNavigationTarget(place);
    setCode("");
  };

  const stopNavigation = () => {
    setCurrentNavigationTarget(null);
  };

  const syncSubmits = async () => {
    setIsSyncing(true)
    try{
      await sendSubmitsToGoogleSheet(allSubmits);
      await fetchAndUpdateTeamSubmits();
    } catch (error) {
      console.log(error)
    } finally {
      setIsSyncing(false)
    }
  }

  return (
    <VStack spacing={4} align="stretch" padding={4}>
      <HStack justifyContent="space-between">
        <img src={logo} alt="logo" width={150} />
        <VStack alignItems="flex-end">
          <Text align="right"><b>Kód tímu</b><br/> {teamCode}</Text>
          <Button colorScheme="primary" onClick={syncSubmits} isLoading={isSyncing}>Synchronizuj</Button>
        </VStack>
      </HStack>
      {currentNavigationTarget === null ? (
        <>
          <InputCode
            code={code}
            onCodeChange={handleCodeChange}
            onSubmit={onSubmit}
          />
          <Divider />
          <CheckpointInfo
            lastCheckpointSubmit={lastCheckpointSubmit}
            currentTime={currentTime}
          />
        </>
      ) : (
        <NavigationInfo
          currentNavigationTarget={currentNavigationTarget}
          onStopNavigation={stopNavigation}
        />
      )}
      <Divider />
      <SubmitsTable submits={allSubmits} />
      <Button onClick={logout} colorScheme="primary">Odhlásiť</Button>
    </VStack>
  );
};
