import { useEffect, useState } from "react";
import {
  point,
  distance as calculateDistance,
  bearing as calculateBearing,
} from "@turf/turf";

export const useNavigation = (targetCoordinates?: {
  lat: number;
  lng: number;
}) => {
  const [distance, setDistance] = useState<number | null>(null);
  const [bearing, setBearing] = useState<number | null>(null);
  const [watchId, setWatchId] = useState<number | null>(null);


  useEffect(() => {
    if (!targetCoordinates) return
    const id = navigator.geolocation.watchPosition(
      (position) => {
        const userPoint = point([
          position.coords.longitude,
          position.coords.latitude,
        ]);
        const targetPoint = point([
          targetCoordinates.lng,
          targetCoordinates.lat,
        ]);

        setDistance(
          calculateDistance(userPoint, targetPoint, { units: "meters" }),
        );
        setBearing(calculateBearing(userPoint, targetPoint));
      },
      (error) => console.error(error),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );

    setWatchId(id);

    return () => {
      if (watchId !== null) {
        navigator.geolocation.clearWatch(watchId);
      }
      setDistance(null);
      setBearing(null);
    };
  }, [targetCoordinates]);

  return { distance, bearing };
};
