// App.tsx
import React from "react";
import { TeamLogin } from "./components/TeamLogin";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { MainApp } from "./components/MainApp";
import { ProtectedRoute } from "./components/ProtectedRoute";
import {Results} from "./components/Results";
import {TeamProgress} from "./components/TeamProgress";

export const App = () => {
  return (
    <Router>
      <Box textAlign="center">
        <Routes>
          <Route path="/" element={<ProtectedRoute><MainApp /></ProtectedRoute>} />
          <Route path="/login" element={<TeamLogin />} />
          <Route path="/results" element={<Results />} />
          <Route path="/progress" element={<TeamProgress />} />
        </Routes>
      </Box>
    </Router>
  );
};
