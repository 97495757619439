import React from "react";
import { Button, Input, VStack } from "@chakra-ui/react";

type Props = {
  code: string;
  onCodeChange: (code: string) => void;
  onSubmit: () => void;
};

export const InputCode: React.FC<Props> = ({
  code,
  onCodeChange,
  onSubmit,
}) => {
  return (
    <VStack spacing={4} align="stretch">
      <Input
        value={code}
        onChange={(e) => onCodeChange(e.target.value)}
        placeholder="Zadaj kód"
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
      />
      <Button onClick={onSubmit} colorScheme="primary">
        Submit
      </Button>
    </VStack>
  );
};
