import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: {
      50: "#ffe0f0",
      100: "#fab4d1",
      200: "#f688b3",
      300: "#f25c95",
      400: "#ef3077",
      500: "#cd3365",
      600: "#a92651",
      700: "#861a3d",
      800: "#620e29",
      900: "#3f0315",
    },
  },
  fonts: {
    heading: "Jost, sans-serif",
    body: "Jost, sans-serif",
  },
});
