const SKIP_MIN_THRESHOLD_MINUTES = 60;
const SKIP_MAX_THRESHOLD_MINUTES = 150;

export const getSkipInfo = (elapsedMinutes: number) => {
  if (elapsedMinutes < SKIP_MIN_THRESHOLD_MINUTES) {
    return "Nedá sa zobrať";
  }
  if (elapsedMinutes >= SKIP_MAX_THRESHOLD_MINUTES) {
    return "Môžete si zobrať bez penalizácie";
  }

  return `Môžete si zobrať s penalizáciou ${
    SKIP_MAX_THRESHOLD_MINUTES - elapsedMinutes
  } min`;
};

export type Place = {
  code: string;
  coordinates: {
    lat: number;
    lng: number;
  };
};
