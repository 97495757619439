import { useState, useEffect } from "react";

const TIMER_REFRESH_INTERVAL = 10000;

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, TIMER_REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  return currentTime;
};
