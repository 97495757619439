import { useEffect, useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import {ResultsData, fetchResults} from "../api";
import {formatDuration} from "date-fns";

export const Results: React.FC = () => {
  const [results, setResults] = useState<ResultsData>([]);

  useEffect(() => {
    const getResults = async () => {
      const data = await fetchResults();
      if (!data) return;
      data.sort((a, b) => {
        if (a.lastCheckpoint !== b.lastCheckpoint) {
          return b.lastCheckpoint - a.lastCheckpoint; // Descending order for checkpoints
        }
        return a.finalTime - b.finalTime; // Ascending order for time
      });
      setResults(data);
    };

    getResults();
  }, []);

  const formatTime = (milliseconds: number) => {
    const duration = {
      hours: Math.floor(milliseconds / 3600000),
      minutes: Math.floor((milliseconds % 3600000) / 60000),
      seconds: Math.floor((milliseconds % 60000) / 1000),
    };
    return formatDuration(duration);
  };

  return (
    <Box p={4}>
      <Text fontSize="xl" mb={4}>Team Results</Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Team</Th>
            <Th>Last Checkpoint</Th>
            <Th>Total Time</Th>
            <Th>Penalties</Th>
            <Th>Final Time</Th>
          </Tr>
        </Thead>
        <Tbody>
          {results.map(result => (
            <Tr key={result.team}>
              <Td>{result.team}</Td>
              <Td>{result.lastCheckpoint}</Td>
              <Td>{formatTime(result.totalTime)}</Td>
              <Td>{formatTime(result.penalties)}</Td>
              <Td>{formatTime(result.finalTime)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
